body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e5e5e5;
}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Pastikan body setinggi viewport */
}
html,
body {
  margin: 0;
  padding: 0;
  // border: 1px solid;
  position: relative;
  // background-color: aqua;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
/* html {
  background-color: #e5e5e5;
}

body {
  background-color: #e5e5e5;
} */

#root {
  // border: 1px solid red;
  background-color: #e5e5e5;
  height: 100%;
  // height: 100vh;
}

.app {
  // border: 3px solid green;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  // min-height: 100% !important;
  background-color: #e5e5e5;
}

.app-wrapper {
  flex-grow: 1;
  // border: 1px solid red;
  // background-color: #e5e5e5;
  position: relative;
}

.wrapper {
  min-height: 100%;
  position: relative;
  background-color: #e5e5e5;
  // border: 1px solid #000;
  display: flex;
  align-items: center;
  flex-direction: column;
}

#footer {
  padding: 20px 0px 20px 0px;
  background-color: #2f253a;
  /* min-height: 100px; */
  margin-top: 50px;
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
  // position: absolute;
  bottom: 0;
}
